import React from "react";
import { Col, Row, Typo } from "@maxeb/admin-ui";
import config from "../configs/config";
import { ILinks, ITest } from "../interface/IConfig";
import Request from "./Request";
import Documentation from "./DocumentationComponent";
import Test from "./Test";

export interface IProps extends ILinks {
  url: string;
}

export interface IState {
  links: {
    path: string;
    method: string;
    test: ITest[];
  }[];
}

class Output extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let links: IState["links"] = [];
    config.forEach((service) => {
      service.links.forEach((resource) => {
        links.push({
          path: resource.path,
          method: resource.method,
          test: resource.test,
        });
      });
    });
    this.state = {
      links: links,
    };
  }

  render() {
    const { path, method, test, documentation } = this.props;

    return (
      <>
        <Row vertical spacing={32} root={0}>
          {documentation && (
            <Documentation properties={documentation.properties} />
          )}
          {test.map((item: ITest, index: number) => {
            return (
              <Col xs={5} key={"tests-" + index}>
                <Typo variant="section" margin="0px 0px 8px 0px">
                  Example: {this.props.method.toLowerCase()}
                  {this.props.path}
                </Typo>
                <Request
                  test={item}
                  httpMethod={method}
                  path={path}
                  url={this.props.url}
                />
              </Col>
            );
          })}
          {test.map((item: ITest, index: number) => {
            return (
              <Col xs={5} key={"response-" + index}>
                <Typo variant="section" margin="0px 0px 8px 0px">
                  Request: {this.props.method.toLowerCase()}
                  {this.props.path}
                </Typo>
                <Row spacing={32} root={0}>
                  <Typo variant="h2" margin="0px 0px 8px 0px">
                    Please click the Button Test to run test
                  </Typo>
                  <Test
                    httpMethod={method}
                    queryStringParameters={item.request.queryStringParameters}
                    headers={item.request.headers}
                    body={item.request.body}
                    url={this.props.url}
                    endpoints={this.props.path}
                    expectedData={item.response}
                  />
                </Row>
              </Col>
            );
          })}
        </Row>
      </>
    );
  }
}

export default Output;
