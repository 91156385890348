import React from "react";
import { Col, ITheme, Row, useClasses } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/pro-light-svg-icons";

const styles = (theme: ITheme) => ({
  link: {
    textDecoration: "none",
    color: "white",
  },
});

const Logo: React.FC = (props) => {
  const classes = useClasses(styles, props);
  return (
    <Row spacing={16} root={0}>
      <Col xs="auto">
        <FontAwesomeIcon icon={faBook} style={{ fontSize: "2.4rem" }} />
      </Col>
      <Col xs="calc(100% - 55px)">
        <div style={{ fontSize: "1.4rem", marginTop: "2px" }}>
          <a className={classes.link} href="/">
            API-Dokumentation
          </a>
        </div>
      </Col>
    </Row>
  );
};

export default Logo;
