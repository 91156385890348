import IConfig from "../interface/IConfig";
import ApiKey_Add from "./tests/ApiKey_Add";
import ApiKey_Delete from "./tests/ApiKey_Delete"
import ApiKey_Get from "./tests/ApiKey_Get"
import App_Add from "./tests/App_Add"
import App_Delete from "./tests/App_Delete"
import IDP_Challenge_Email from "./tests/IDP_Challenge_Email"
import IDP_Challenge_Password from "./tests/IDP_Challenge_Password"
import IDP_Leave_Team_As_Owner from "./tests/IDP_Leave_Team_As_Owner"
import IDP_Login from "./tests/IDP_Login"
import IDP_Reset_Password from "./tests/IDP_Reset_Password"
import IDP_Settings from "./tests/IDP_Settings"
import IDP_Settings_Get from "./tests/IDP_Settings_Get"
import IDP_SignUp from "./tests/IDP_SignUp"
import IDP_Swap_Team from "./tests/IDP_Swap_Team"
import IDP_WhoAmI from "./tests/IDP_WhoAmI"
import Role_Add from "./tests/Role_Add"
import Role_Delete from "./tests/Role_Delete"
import Team_Add from "./tests/Team_Add"
import Team_Delete from "./tests/Team_Delete"
import Team_Get from "./tests/Team_Get"
import User_Delete from "./tests/User_Delete"
import User_Get_Email_Reset_Secret from "./tests/User_Get_Email_Reset_Secret"
import User_Update_Make_Admin from "./tests/User_Update_Make_Admin"
import { faCube } from "@fortawesome/pro-light-svg-icons";

import { DATA_INIT } from "../interface/TStatus";

const services: IConfig[] = [
  {
    icon: faCube,
    url: "idp.datenbanker.at",
    label: "IDP",
    open: true,
    collapsAble: true,
    links: [
      {
        path: "/idp/login",
        method: "POST",
        test: IDP_Login,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-key"
            },
            {
              name: "body.domain",
              type: ["string"],
              required: true,
              description: "unit-test.at"
            },
            {
              name: "body.sameSite",
              type: ["string"],
              required: true,
              description: "Lax"
            },
            {
              name: "body.allowAdminFunctions",
              type: ["boolean"],
              required: true,
              description: "false"
            },
            {
              name: "body.key",
              type: ["string"],
              required: true,
              description: "aRndKey"
            },
            {
              name: "body.app",
              type: ["string"],
              required: true,
              description: "###app###"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/apikey",
        method: "POST",
        test: ApiKey_Add,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-key"
            },
            {
              name: "body.domain",
              type: ["string"],
              required: true,
              description: "unit-test.at"
            },
            {
              name: "body.sameSite",
              type: ["string"],
              required: true,
              description: "Lax"
            },
            {
              name: "body.allowAdminFunctions",
              type: ["boolean"],
              required: true,
              description: "false"
            },
            {
              name: "body.key",
              type: ["string"],
              required: true,
              description: "aRndKey"
            },
            {
              name: "body.app",
              type: ["string"],
              required: true,
              description: "###app###"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/apikey",
        method: "GET",
        test: ApiKey_Get,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["string"],
              required: true,
              description: "ID: ###apiKeyId###"
            },
            {
              name: "QueryStringParameter.projection",
              type: ["id", "key"],
              required: true,
              description: "id, key"

            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "10"
            },
            {
              name: "QueryStringParameter.direction",
              type: ["string"],
              required: true,
              description: "ASC"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-app"
            },
            {
              name: "body.secret",
              type: ["string"],
              required: true,
              description: "secret"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/apikey",
        method: "DELETE",
        test: ApiKey_Delete,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["string"],
              required: true,
              description: "ID: ###apiKeyId###"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-key"
            },
            {
              name: "body.domain",
              type: ["string"],
              required: true,
              description: "example.com"
            },
            {
              name: "body.key",
              type: ["string"],
              required: true,
              description: "aRndKey"
            },
            {
              name: "body.app",
              type: ["string"],
              required: true,
              description: "###app###"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/app",
        method: "POST",
        test: App_Add,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-app"
            },
            {
              name: "body.audition",
              type: ["string"],
              required: true,
              description: "unit-test"
            },
            {
              name: "body.passwordCapitalLetter",
              type: ["boolean"],
              required: true,
              description: "false"
            },
            {
              name: "body.passwordNumber",
              type: ["boolean"],
              required: true,
              description: "true"
            },
            {
              name: "body.passwordMinimumLength",
              type: ["number"],
              required: true,
              description: "8"
            },
            {
              name: "body.user",
              type: ["string"],
              required: true,
              description: "user"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/app",
        method: "DELETE",
        test: App_Delete,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["string"],
              required: true,
              description: "ID: ###app###"
            },
            {
              name: "QueryStringParameter.projection",
              type: [""],
              required: true,
              description: ""
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "10"
            },
            {
              name: "QueryStringParameter.sort",
              type: ["string"],
              required: true,
              description: "title"
            },
            {
              name: "QueryStringParameter.direction",
              type: ["string"],
              required: true,
              description: "ASC"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-key"
            },
          ]
        }
      },
      {
        path: "/idp/team",
        method: "POST",
        test: Team_Add,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###sessionAdmin###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-usergroup"
            },
            {
              name: "body.app",
              type: ["string"],
              required: true,
              description: "###app###"
            },
            {
              name: "body.owner",
              type: ["string"],
              required: true,
              description: "###user###"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/team/swap",
        method: "POST",
        test: IDP_Swap_Team,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: [""],
              required: true,
              description: ""
            },
            {
              name: "QueryStringParameter.projection",
              type: [""],
              required: true,
              description: ""
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "1"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###session###"
            },
            {
              name: "body.team",
              type: ["string"],
              required: true,
              description: "###team1###"
            },
            {
              name: "body.owner",
              type: ["string"],
              required: true,
              description: "###user###"
            },
          ]
        }
      },
      {
        path: "/idp/team/leave",
        method: "POST",
        test: IDP_Leave_Team_As_Owner,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id"],
              required: true,
              description: "###team1###"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###session###"
            },
            {
              name: "body.id",
              type: ["string"],
              required: true,
              description: "###team1###"
            },
          ]
        }
      },
      {
        path: "/idp/team",
        method: "GET",
        test: Team_Get,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["string"],
              required: true,
              description: "###team###"
            },
            {
              name: "QueryStringParameter.projection",
              type: ["string"],
              required: true,
              description: "id"
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "1"
            },
            {
              name: "QueryStringParameter.direction",
              type: ["string"],
              required: true,
              description: "ASC"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://admin-unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey2###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###sessionAdmin###"
            },
          ]
        }
      },
      {
        path: "/idp/team",
        method: "DELETE",
        test: Team_Delete,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["string"],
              required: true,
              description: "###team###"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://admin-unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey2###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###sessionAdmin###"
            },
          ]
        }
      },
      {
        path: "/idp/user",
        method: "GET",
        test: User_Get_Email_Reset_Secret,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id, app"],
              required: true,
              description: "ID: ###user###, App: ###app###"
            },
            {
              name: "QueryStringParameter.projection",
              type: ["string"],
              required: true,
              description: "id, secrets"
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "1"
            },
            {
              name: "QueryStringParameter.direction",
              type: ["string"],
              required: true,
              description: "ASC"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://admin-unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey2###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: true,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "test-app"
            },
            {
              name: "body.secret",
              type: ["string"],
              required: true,
              description: "secret"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/user",
        method: "PATCH",
        test: User_Update_Make_Admin,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id, app"],
              required: true,
              description: "ID: ###userAdmin###, App: ###app###"
            },
            {
              name: "QueryStringParameter.projection",
              type: ["string"],
              required: true,
              description: "id, secrets"
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "1"
            },
            {
              name: "QueryStringParameter.direction",
              type: ["string"],
              required: true,
              description: "ASC"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://admin-unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey2###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.roles",
              type: ["string"],
              required: true,
              description: "add: [admin]"
            },
          ]
        }
      },
      {
        path: "/idp/user",
        method: "DELETE",
        test: User_Delete,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id"],
              required: true,
              description: "ID: ###team###"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://localhost:3000"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
          ]
        }
      },
      {
        path: "/idp/register",
        method: "POST",
        test: IDP_SignUp,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "body.email",
              type: ["string"],
              required: true,
              description: "insert email"
            },
            {
              name: "body.password",
              type: ["string"],
              required: true,
              description: "insert password"
            },
            {
              name: "body.firstname",
              type: ["string"],
              required: true,
              description: "insert first name"
            },
            {
              name: "body.lastname",
              type: ["boolean"],
              required: true,
              description: "insert last name"
            },
          ]
        }
      },
      {
        path: "/idp/role",
        method: "POST",
        test: Role_Add,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://admin-unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey2###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###authorizationKey###"
            },
            {
              name: "body.name",
              type: ["string"],
              required: true,
              description: "user"
            },
            {
              name: "body.policies",
              type: ["string"],
              required: true,
              description: "user:id:#user.id"
            },
            {
              name: "body.app",
              type: ["string"],
              required: true,
              description: "###app###"
            },
            {
              name: "body.state",
              type: ["string"],
              required: true,
              description: "active"
            },
          ]
        }
      },
      {
        path: "/idp/role",
        method: "DELETE",
        test: Role_Delete,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id"],
              required: true,
              description: "ID: ###role###"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://admin-unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###AuthorizationKey###"
            },
          ]
        }
      },
      {
        path: "/idp/whoami",
        method: "GET",
        test: IDP_WhoAmI,
        documentation: {
          properties:
          [             
          {
            name: "QueryStringParameter.condition",
            type: [""],
            required: true,
            description: ""
          },
          {
            name: "QueryStringParameter.projection",
            type: [""],
            required: true,
            description: ""
          },
          {
            name: "QueryStringParameter.limit",
            type: ["number"],
            required: true,
            description: "1"
          },
          {
            name: "header.content-type",
            type: ["string"],
            required: true,
            description: "Set content type to application/json; charset=utf8"
          },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###session###"
            },
          ]
        }
      },
      {
        path: "/idp/settings",
        method: "GET",
        test: IDP_Settings_Get,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id"],
              required: true,
              description: "###user###"
            },
            {
              name: "QueryStringParameter.projection",
              type: [""],
              required: true,
              description: ""
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "1"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###session###"
            },
          ]
        }
      },
      {
        path: "/idp/settings",
        method: "PATCH",
        test: IDP_Settings,
        documentation: {
          properties:
          [
            {
              name: "QueryStringParameter.condition",
              type: ["id"],
              required: true,
              description: "###user###"
            },
            {
              name: "QueryStringParameter.projection",
              type: [""],
              required: true,
              description: ""
            },
            {
              name: "QueryStringParameter.limit",
              type: ["number"],
              required: true,
              description: "1"
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "header.cookie",
              type: ["string"],
              required: false,
              description: "###session###"
            },
            {
              name: "body.firstname",
              type: ["string"],
              required: true,
              description: "insert first name"
            },
          ]
        }
      },
      {
        path: "/idp/challenge/email",
        method: "POST",
        test: IDP_Challenge_Email,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "body.email",
              type: ["string"],
              required: true,
              description: "insert email"
            },
            {
              name: "body.secret",
              type: ["string"],
              required: true,
              description: "###emailChallenge###"
            },
          ]
        }
      },
      {
        path: "/idp/challenge/password",
        method: "POST",
        test: IDP_Challenge_Password,
        documentation: {
          properties:
          [
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "body.username",
              type: ["string"],
              required: true,
              description: "insert username/email"
            },
            {
              name: "body.password",
              type: ["string"],
              required: true,
              description: "insert password"
            },
            {
              name: "body.secret",
              type: ["string"],
              required: true,
              description: "###passwordChallenge###"
            },
          ]
        }
      },
      {
        path: "/idp/reset/password",
        method: "POST",
        test: IDP_Reset_Password,
        documentation: {
          properties:
          [
            {
              name: "header.access-control-allow-headers",
              type: ["string"],
              required: true,
              description: "Content-Type, X-Amz-Date, Authorization, X-Api-Key, X-Amz-Security-Token, X-Amz-User-Agent"
            },
            {
              name: "header.access-control-allow-origin",
              type: ["string"],
              required: true,
              description: "http://localhost:3000"
            },
            {
              name: "header.access-control-request-method",
              type: ["string"],
              required: true,
              description: "OPTIONS, POST, GET, PATCH, PUT, DELETE",
            },
            {
              name: "header.content-type",
              type: ["string"],
              required: true,
              description: "Set content type to application/json; charset=utf8"
            },
            {
              name: "header.origin",
              type: ["string"],
              required: true,
              description: "Indicates the origin that caused the request: http://unit-test.at"
            },
            {
              name: "header.x-api-key",
              type: ["string"],
              required: true,
              description: "Provide a key for project authorization in the format ###apiKey###"
            },
            {
              name: "body.username",
              type: ["string"],
              required: true,
              description: "insert email"
            },
          ]
        }
      },
    ],
  },
];

export default services;