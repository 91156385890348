import React from "react";
import { Container, Form, Button, Row, Typo, TextInput } from "@maxeb/admin-ui";
import { ILinks, ITest } from "../interface/IConfig";
import Block from "./Block";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileEdit } from "@fortawesome/pro-light-svg-icons";

export interface IProps {
  test: ITest;
  httpMethod: ILinks["method"];
  path: ILinks["path"];
  url: string;
}

export interface IState {
  editing: boolean;
  values: { queryStringParameters: string; Header: string; Body: string };
  text: { Edit: string; Stop: string };
  editError: boolean;
}

class Request extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      values: {
        queryStringParameters: JSON.stringify({
          ...this.props.test.request.queryStringParameters,
          q: window.btoa(this.props.test.request.queryStringParameters.q),
        }),
        Header: JSON.stringify(this.props.test.request.headers),
        Body: JSON.stringify(this.props.test.request.body),
      },
      editing: false,
      text: { Edit: "Edit", Stop: "Stop" },
      editError: false,
    };
  }

  isValidJson(a: string) {
    try {
      JSON.parse(a);
    } catch (e) {
      return false;
    }
    return true;
  }

  render() {
    const { editing } = this.state;
    const editButton = editing === true;

    return (
      <>
        <Container fluid spacing={0}>
          <Form>
            <Block stretch>
              <Typo variant="h2">Config Description</Typo>
              <TextInput
                label={
                  "URL:" +
                  this.props.url +
                  this.props.path +
                  this.props.httpMethod
                }
                rows={2}
              />
              <Typo variant="h2">Query String Parameters</Typo>

              <TextInput
                rows={5}
                onChange={(value) => {
                  this.setState({
                    ...this.state,
                    values: {
                      ...this.state.values,
                      queryStringParameters: value || "",
                    },
                  });
                }}
                value={
                  editButton
                    ? this.state.values.queryStringParameters
                    : JSON.stringify({
                        ...this.props.test.request.queryStringParameters,
                        q: window.btoa(
                          this.props.test.request.queryStringParameters.q
                        ),
                      })
                }
              />
              {editButton &&
                !this.isValidJson(this.state.values.queryStringParameters) && (
                  <Typo variant="section">
                    Not valid JSON in Query String Parameters!
                  </Typo>
                )}

              <Typo variant="h2">Header</Typo>
              <TextInput
                rows={5}
                onChange={(value) => {
                  this.setState({
                    ...this.state,
                    values: {
                      ...this.state.values,
                      Header: value || "",
                    },
                  });
                }}
                value={
                  editButton
                    ? this.state.values.Header
                    : JSON.stringify(this.props.test.request.headers)
                }
              />
              {editButton && !this.isValidJson(this.state.values.Header) && (
                <Typo variant="section">Not valid JSON in Header!</Typo>
              )}
              <Typo variant="h2">Body</Typo>
              <TextInput
                rows={4}
                onChange={(value) => {
                  this.setState({
                    ...this.state,
                    values: {
                      ...this.state.values,
                      Body: value || "",
                    },
                  });
                }}
                value={
                  editButton
                    ? this.state.values.Body
                    : JSON.stringify(this.props.test.request.body)
                }
              />
              {editButton && !this.isValidJson(this.state.values.Body) && (
                <Typo variant="section">Not valid JSON in Body!</Typo>
              )}
              <Row
                vertical
                root={{ top: 8, left: 0, bottom: 0, right: 0 }}
                spacing={8}
                horizontalAlign="left"
              >
                <Button
                  xs="120px"
                  primary
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      editing: !this.state.editing,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{ margin: "0px 4px 0px 0px" }}
                    icon={faFileEdit}
                  />
                  {!editButton
                    ? this.state.text.Edit
                    : "Edit" || editButton
                    ? this.state.text.Stop
                    : "Stop"}
                </Button>
              </Row>
            </Block>
          </Form>
        </Container>
      </>
    );
  }
}

export default Request;
