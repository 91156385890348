import React from "react";
import { Button, Row, Typo } from "@maxeb/admin-ui";
import ReactJson from "@microlink/react-json-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faTrashCan } from "@fortawesome/pro-light-svg-icons";

export interface TStatus {
  status: "DATA_INIT" | "DATA_PENDING" | "DATA_SUCCESS" | "DATA_ERROR";
}

export interface IProps {
  httpMethod: "POST" | "GET" | "PATCH" | "DELETE";
  queryStringParameters: { [key: string]: string };
  headers: { [key: string]: string };
  body: { [key: string]: any };
  endpoints: string;
  url: string;
  expectedData: {
    statusCode: number;
    headers: { [key: string]: string };
    body: { [key: string]: any };
  };
}

export interface IState {
  status: string;
  testFailed: boolean;
}

const isLiteral = (a: any): boolean => {
  return (
    typeof a === "string" ||
    typeof a === "boolean" ||
    typeof a === "number" ||
    typeof a === "undefined" ||
    typeof a === "function" ||
    a === null
  );
};
const isArray = (a: any): boolean => {
  return Array.isArray(a);
};
const isArrayEqual = (a: any[], b: any[]): boolean => {
  if (a.length !== b.length) return false;

  const ok: boolean =
    a.find((item, index) => !isDeepEqual(a[index], b[index])) === undefined;

  return ok;
};
const isObject = (a: any) => {
  return a !== null && typeof a === "object" && !Array.isArray(a);
};
const isObjectEqual = (a: any, b: any): boolean => {
  const keys1 = Object.keys(a);
  const keys2 = Object.keys(b);
  if (keys1.length !== keys2.length) return false;

  const ok: boolean =
    keys1.find((key) => !isDeepEqual(a[key], b[key])) === undefined;

  return ok;
};

export const isDeepEqual = (a: any, b: any): boolean => {
  //console.log(a, b);
  if (typeof a !== typeof b) return false;
  if (isLiteral(a) && isLiteral(b)) {
    const result = a === b;
    //console.log("liter", result);
    return result;
  }
  if (isArray(a) && isArray(b)) {
    const result = isArrayEqual(a, b);
    //console.log("array", result);
    return result;
  }
  if (isObject(a) && isObject(b)) {
    const result = isObjectEqual(a, b);
    //console.log("obj", result);
    return result;
  }
  //console.log("type", false);
  return false;
};

export const plainGetData = (
  method: "POST" | "GET" | "PATCH" | "DELETE",
  endpoint: string,
  queryStringParameters: { [key: string]: string },
  header: { [key: string]: string },
  body: { [key: string]: any }
): Promise<XMLHttpRequest> => {
  const tests = new XMLHttpRequest();
  tests.open(method, endpoint);
  for (const [key, value] of Object.entries(header)) {
    tests.setRequestHeader(key, value);
  }
  tests.send(JSON.stringify(body));
  return new Promise((resolve, reject) => {
    tests.onreadystatechange = function () {
      if (tests.readyState === 4 && tests.status === 200) {
        resolve(tests);
      } else if (tests.readyState === 4) reject(tests);
    };
  });
};

class Test extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { status: "init", testFailed: false };
  }

  async GetData(
    method: "POST" | "GET" | "PATCH" | "DELETE",
    endpoint: string,
    queryStringParameters: { [key: string]: string },
    header: { [key: string]: string },
    body: { [key: string]: any }
  ) {
    this.setState({ ...this.setState, status: "pending" });
    try {
      const result = await plainGetData(
        method,
        endpoint,
        queryStringParameters,
        header,
        body
      );
      //if statuscode ok
      const isSameStatus = result.status === this.props.expectedData.statusCode;
      if (isSameStatus) {
        const response = JSON.parse(result.response);
        if (isDeepEqual(response, this.props.expectedData)) {
          console.log(
            "isEqual: ",
            isDeepEqual(Response, this.props.expectedData)
          );
          return this.setState({ ...this.setState, status: "ok" });
        }
      }
    } catch (error) {}

    this.setState({ ...this.setState, status: "error", testFailed: true });
    //console.log("notEqual");
    //console.log(this.state);
  }

  /*   updateState = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("Success!");
      this.setState({...this.setState,
        status: " finished",
      });
    }, 2000);
  })
    .then((resp) => console.log(resp))
    .catch((err) => {
      console.log(err);
    }); */

  render() {
    const { status } = this.state;
    const buttonVisible = status === "init" || status === "ok";
    const hasError = status === "error";

    return (
      <>
        <Row
          vertical
          root={{ top: 8, left: 0, bottom: 0, right: 0 }}
          spacing={8}
          horizontalAlign="left"
        >
          {buttonVisible && (
            <Button
              xs="120px"
              success
              onClick={() => {
                this.GetData(
                  this.props.httpMethod,
                  "https://" + this.props.url + this.props.endpoints,
                  this.props.queryStringParameters,
                  this.props.headers,
                  this.props.body
                );
              }}
            >
              <FontAwesomeIcon
                style={{ margin: "0px 4px 0px 0px" }}
                icon={faCheckSquare}
              />
              Test
            </Button>
          )}
          {
            <Button
              xs="120px"
              danger
              onClick={() => {
                this.setState({ ...this.setState, status: "init" });
              }}
            >
              <FontAwesomeIcon
                style={{ margin: "0px 4px 0px 0px" }}
                icon={faTrashCan}
              />
              Reset
            </Button>
          }
        </Row>
        {!hasError && !buttonVisible && "Pending..."}
        {!hasError && buttonVisible && status === "ok" && "Success!"}
        {hasError && !buttonVisible && "Ein Fehler ist aufgetreten."}
        {this.state.testFailed && hasError && !buttonVisible && (
          <div>
            <Typo variant="info">
              {"isEqual:  " + isDeepEqual(Response, this.props.expectedData)}
              <br />
              {"Response:  " + this.props.httpMethod}
              <br />
              {"URL:  https://" + this.props.url + this.props.endpoints}
            </Typo>
            <ReactJson
              src={this.props.queryStringParameters}
              theme={"summerfruit"}
              collapsed={false}
            />
            <ReactJson
              src={this.props.headers}
              theme={"summerfruit"}
              collapsed={false}
            />
            <ReactJson
              src={this.props.body}
              theme={"summerfruit"}
              collapsed={false}
            />
          </div>
        )}
      </>
    );
  }
}

export default Test;
