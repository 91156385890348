import React from "react";
import {
  Container,
  AdminLayout,
  Routes,
  Route,
  useRouter,
  AdminPage,
} from "@maxeb/admin-ui";
import Navigation from "./components/Navigation";
import Output from "./components/Output";
import "./index.css";
import services from "./configs/config";

function App() {
  const [router] = useRouter();

  const layoutToLoad = router.path.startsWith("/documentation")
    ? "documentation"
    : "default";

  return (
    <Container fluid spacing={0}>
      {layoutToLoad === "default" && (
        <AdminLayout sidebar={<Navigation />}>
          <Routes>
            {services.map((service, pos) =>
              service.links?.map((link, linkPos) => (
                <Route path={`${link.path}/${link.method.toLowerCase()}`}>
                  <AdminPage title={"IDP"} breadcrumbs={[]}>
                    <Output
                      path={link.path}
                      method={link.method}
                      test={link.test}
                      url={service.url}
                      documentation={link.documentation}
                    />
                  </AdminPage>
                </Route>
              ))
            )}
          </Routes>
        </AdminLayout>
      )}
    </Container>
  );
}

export default App;
