import { ITest } from "../../interface/IConfig";
import { DATA_INIT } from "../../interface/TStatus";

const tests: ITest[] = [
  {
    request: {
    httpMethod: "GET",
    queryStringParameters: {condition: {id: "###apiKeyId###"}, projection: ["id", "key"], limit: "10", direction: "ASC"},
    headers: {
        "Content-Type": "application/json; charset=utf8",
        "Accept-Encoding": "gzip, deflate, sdch",
        "Accept-Language": "en-US,en;q=0.8",
        Origin: "http://localhost:3000",
        "x-api-key": "###apiKey###",
        Cookie: "###authorizationKey###",
      },
      body: {
        name: "test-app",
        secret: "test12345",
        state: "active"
      },
        path: "/apikey",
    },
    response: {
      statusCode: 200,
      headers: { 
      "Access-Control-Allow-Headers": "Content-Type, X-Amz-Date, Authorization, X-Api-Key, X-Amz-Security-Token, X-Amz-User-Agent",
      "Access-Control-Allow-Origin": "http://localhost:3000",
      "Access-Control-Request-Method": "OPTIONS, POST, GET, PATCH, PUT, DELETE",
      "content-length": "72",
      "content-type": "application/json" },
      body: {
        data: [ {id: "###apiKeyId###", key: "###apiKey###"}]
      },
    },
    status: DATA_INIT,
  },
];

export default tests;