import { ITest } from "../../interface/IConfig";
import { DATA_INIT } from "../../interface/TStatus";

const tests: ITest[] = [
  {
    request: {
    httpMethod: "GET",
    queryStringParameters: {
        condition: {
            id: "###user###"
          },
          projection: [],
          limit: "1"
    },
    headers: {
        "Content-Type": "application/json; charset=utf8",
        "Accept-Encoding": "gzip, deflate, sdch",
        "Accept-Language": "en-US,en;q=0.8",
        Origin: "http://unit-test.at",
        "x-api-key": "###apiKey###",
        Cookie: "###session###"
      },
      body: {},
      path: "/idp/settings",
    },
    response: {
      statusCode: 200,
      headers: { 
        "Access-Control-Allow-Headers": "Content-Type, X-Amz-Date, Authorization, X-Api-Key, X-Amz-Security-Token, X-Amz-User-Agent",
        "Access-Control-Allow-Origin": "http://unit-test.at",
        "Access-Control-Request-Method": "OPTIONS, POST, GET, PATCH, PUT, DELETE",
        "content-length": "93",
        "content-type": "application/json"
      },
      body: {
        status: "ok",
        data: {
          email: "max@artcare.at",
          firstname: "Maximilian",
          lastname: "Ebert"
        }
      },
    },
    status: DATA_INIT,
  },
];

export default tests;