import { ITest } from "../../interface/IConfig";
import { DATA_INIT } from "../../interface/TStatus";

const tests: ITest[] = [
  {
    request: {
    httpMethod: "DELETE",
    queryStringParameters: {
        condition: {
            id: "###role###"
          }
    },
    headers: {
        "Content-Type": "application/json; charset=utf8",
        "Accept-Encoding": "gzip, deflate, sdch",
        "Accept-Language": "en-US,en;q=0.8",
        Origin: "http://admin-unit-test.at",
        "x-api-key": "###apiKey2###",
        Cookie: "###AuthorizationKey###"
      },
      body: {},
      path: "/role",
    },
    response: {
      statusCode: 200,
      headers: { 
        "Access-Control-Allow-Headers": "Content-Type, X-Amz-Date, Authorization, X-Api-Key, X-Amz-Security-Token, X-Amz-User-Agent",
        "Access-Control-Allow-Origin": "http://admin-unit-test.at",
        "Access-Control-Request-Method": "OPTIONS, POST, GET, PATCH, PUT, DELETE",
        "content-length": "54",
        "content-type": "application/json"
      },
      body: {
        data: {
            id: "###role###"
          }
      },
    },
    status: DATA_INIT,
  },
];

export default tests;