import React from "react";
import {
  ITheme,
  useClasses,
  Sidebar,
  SidebarList,
  SidebarSection,
  SidebarListLink,
  useRouter,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "./Logo";
import services from "../configs/config";
import classNames from "classnames";

const styles = (theme: ITheme) => ({
  button: {
    cursor: "pointer",
    borderRadius: "4px",
    padding: "4px",
    marginRight: "8px",
    width: "50px",
    display: "inline-block",
  },
  post: {
    background: theme.palette.get("success"),
  },
  get: {
    background: theme.palette.get("primary"),
  },
  patch: {
    background: theme.palette.get("warning"),
    color: "#ffff",
  },
  del: {
    background: theme.palette.get("danger"),
  },
});

export interface INavigation {
  onClose?: () => void;
}

function Navigation(props: INavigation) {
  const [router] = useRouter();
  const currentPath = router.path;
  const classes = useClasses(styles, props);

  return (
    <Sidebar logo={<Logo />} onClose={props.onClose}>
      {services.map((service, pos) => (
        <SidebarSection label={service.label} key={"content-" + pos}>
          <SidebarList
            icon={<FontAwesomeIcon icon={service.icon} />}
            label={service.label}
            collapseAble={service.collapsAble}
            open={service.open}
            key={"content-" + pos}
          >
            {service.links?.map((link, linkPos) => (
              <SidebarListLink
                active={currentPath === link.path}
                to={`${link.path}/${link.method.toLowerCase()}`}
                key={"content-" + pos + "-" + linkPos}
              >
                <span
                  className={classNames([
                    classes.button,
                    link.method === "POST"
                      ? classes.post
                      : link.method === "GET"
                      ? classes.get
                      : link.method === "PATCH"
                      ? classes.patch
                      : link.method === "DELETE"
                      ? classes.del
                      : classes.post,
                  ])}
                >
                  {link.method}
                </span>
                {link.path}
              </SidebarListLink>
            ))}
          </SidebarList>
        </SidebarSection>
      ))}
    </Sidebar>
  );
}

export default Navigation;
